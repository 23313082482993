import React from "react"
import BasicContentBlock from "../content-blocks/BasicContentBlock"
import * as styles from "./photo-gallery.module.scss"
const GalleryList = ({ galleries, enabled }) => {
  if (enabled === "kontent_item_pest") {
    let mapRunsAlt = 0
    return (
      <div className={`container ${styles.pestGrid}`}>
        {galleries.value.map(({ elements, id }) => {
          mapRunsAlt++
          const { image, name, short_description, slug } = elements
          return (
            <BasicContentBlock
              key={id}
              content={short_description}
              image={image}
              subHeading={name}
              slug={slug}
              mapRuns={mapRunsAlt}
            />
          )
        })}
      </div>
    )
  } else {
    return null
  }
}

export default GalleryList
